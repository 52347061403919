import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

function autoCompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string' && control.value != "") {
      return { 'invalidAutoComplete': { value: control.value } }
    }
    return null  /* valid option selected */
  }
}
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {
  @Input() input;
  @Input() data = {};
  @ViewChild('auto') autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() onValueInvalid: EventEmitter<any> = new EventEmitter();
  editForm: FormGroup;
  options = [];
  validation_msgs: any[] = [];
  offset: number = 0;
  searchVal: any;
  limit: number = 20;
  stopScrollFiring: boolean = false;
  placeHolder: string = "";
  dataRetry = 0;
  isS3BucketPagination: boolean = false;
  s3BucketPaginationToken: string = '';
  constructor(private ds: DataService, private fb: FormBuilder, private weds: WaitErrorDialogsService) { }

  ngOnInit(): void {
    this.placeHolder = this.input.placeholder || `Select ${this.input.title}`;
    this.isS3BucketPagination = this.input.isS3BucketPagination || this.isS3BucketPagination;
    let selectedValue = this.input.value || (this.input.isSelectedValueList ? this.data[this.input.key][0] : this.data[this.input.key]);
    this.editForm = this.fb.group({
      inputControl: new FormControl({ value: selectedValue, disabled: this.input.disabled }, [autoCompleteObjectValidator(),...(this.input?.validators || [Validators.required])])
    });
    if (this.input.options?.length) {
      this.editForm.controls['inputControl'].valueChanges.pipe(startWith(''), map((v: string) => this.input.options.filter((f) => { this.options = (f.name || f).toLowerCase().includes((v + '').toLowerCase()) })));
    } else {
      this.editForm.controls['inputControl'].valueChanges.pipe(debounceTime(400)).subscribe(val => {
        if (typeof (val) != 'object') {
          // Clear the form on input change with empty value so user can choose a valid input from the drop-down
          // It also help and set the value to parent form so that it check form validity
          this.selectionChange.emit({ inputObj: this.input, selectedOption: '' });
          this.offset = 0;
          this.searchVal = val;
          if (this.isS3BucketPagination) this.s3BucketPaginationToken = '';
          this.getData();
        }
      });
    }
    this.validation_msgs = [
      { type: 'invalidAutoComplete', message: `${this.input.title} not recognized. Click one of the autocomplete options.` },
      { type: 'required', message: `${this.input.title} is required.` }
    ]
  }

  getDisplayOptions(option: any) {
    if(this.input.isUseExtSkillIdEnabled && option?.textToDisplayInDropDown) {
      return option.textToDisplayInDropDown;
    } else {
      return option.name ||option.certificationName || option[this.input.optionObjectValueKey] || option;
    }
  }

  doInitialLoad() {
    if (this.options.length == 0 && ++this.dataRetry < 3) {
      this.getData();
    }
  }
  getData() {
    let dialogRef;
    if (this.offset > 0) dialogRef = this.weds.showDialog({ type: 'wait', code: -2, dontCloseAllDialogs: true });
    let searchData = { search: this.searchVal, limit: this.limit, forAutoComplete: false, type: this.input.searchType, clientId: this.ds.currentAdminClientId, offset: this.offset, ...(this.input.additionalRequestBody || {}) };
    if (this.isS3BucketPagination) searchData['s3BucketPaginationToken'] = this.s3BucketPaginationToken;
    if (this.input.filterObj) {
      searchData = Object.assign(searchData, this.input.filterObj);
    }
    this.ds.searchData(this.input.endpoint, searchData).pipe(take(1)).subscribe((rs: any) => {
      if (dialogRef) dialogRef.close();
      if (rs.status == 'Success') {
        let data = rs.searchData || rs[this.input.responseKey || 'dummyKey'] || (rs.data || {})[this.input.responseKey || 'dummyKey'] || [];
        if (this.isS3BucketPagination) this.s3BucketPaginationToken = rs.s3BucketPaginationToken;
        this.stopScrollFiring = data.length < this.limit;
        if (this.offset == 0) this.options = [];
        data.map((r) => { if (!this.options.filter((option) => this.compareFilter(option, r))[0]) this.options.push(r); })
        if (data.length || this.stopScrollFiring) this.dataRetry = 0;
      }
    });
  }
  compareFilter(v1: any, v2: any) {
    let idKey;
    if (this.input.idField) {
      idKey = this.input.idField;
    } else {
      for (let key in (v1 || {})) if (key.search(/Id\b/) > 0) { idKey = key; break; }
      for (let key in (v2 || {})) if (key.search(/Id\b/) > 0) { idKey = key; break; }
    }
    if (idKey) return (v1 || {})[idKey] === (v2 || {})[idKey];
    if (v1.id || v2.id) return (v1 || {}).id === (v2 || {}).id;
    return v1 == v2;
  }
  getDisplayName(o: any) {
    // TODO: change this
    return o ?  o.textToDisplayInDropDown || o.name || o.certificationName || o['tagValue'] || o : '';
  }
  public getValue() {
    return this.editForm.valid ? this.editForm.controls.inputControl.value : null;
  }
  onSelectionChange(evt: any) {
    this.selectionChange.emit({ inputObj: this.input, selectedOption: evt.option.value });
  }
  hasError(type: string) {
    if (this.editForm.controls.inputControl.hasError(type)) {
      this.onValueInvalid.emit({ inputObj: this.input });
      return true;
    }
    return false;
  }
  autocompleteScroll() {
    setTimeout(() => {
      if (this.autoComplete && this.autocompleteTrigger && this.autoComplete.panel) {
        fromEvent(this.autoComplete.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.autoComplete.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe(x => {
            const scrollTop = this.autoComplete.panel.nativeElement.scrollTop;
            const scrollHeight = this.autoComplete.panel.nativeElement.scrollHeight;
            const elementHeight = this.autoComplete.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight == scrollTop + elementHeight;
            if (!this.stopScrollFiring && atBottom) {
              this.offset += 20;
              this.getData();
            }
          });
      }
    });
  }
}

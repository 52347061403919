<div class="editable-tbl-con">
    <div class="add-button-con" *ngIf="addButtonText&&addButtonPosition=='top'&&canEdit">
        <button type="button" mat-stroked-button class="secondary-button" (click)="addRow()">{{addButtonText}}</button>
    </div>
    <div class="title" *ngIf="title" class="title">
        <div class="regularfont">{{title}}</div>
    </div>
    <form [formGroup]="editForm" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" class="form-con">
        <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)"
            *ngIf="dataSource.length">
            <ng-container *ngFor="let col of columns;index as i;" matColumnDef="{{col.key}}">
                <th mat-header-cell *matHeaderCellDef>{{col.title}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element['disable'+col.key]||col.disabled" class="{{'disabledCol disabled'+col.type}}">
                        {{element[col.key]}}
                    </div>
                    <div *ngIf="(col.type=='text'||col.type=='number')&&!element['disable'+col.key]&&!col.disabled">
                        <mat-form-field appearance="outline" class=" input-form-field">
                            <mat-icon matPrefix *ngIf="col.icon">{{col.icon}}</mat-icon>
                            <input type="{{col.type}}" matInput formControlName="{{col.key+'_'+element.tblId}}"
                                placeholder="{{col.placeholder}}" [required]="col.required"
                                (change)="selectionChanged(col.key+'_'+element.tblId, i)"
                                [autocomplete]="col.autocomplete||'off'" />
                                <mat-error *ngIf="editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.required">{{col.title}} is required</mat-error>
                                <mat-error *ngIf="editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.max">
                                    {{col.title}} should be less than or equal to {{editForm.get([col.key+'_'+element.tblId]).errors?.max?.max}}
                                </mat-error>
                                <mat-error *ngIf="editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.min">
                                    {{col.title}} should be greater than or equal to {{editForm.get([col.key+'_'+element.tblId]).errors?.min?.min}}
                                </mat-error>
                        </mat-form-field>
                        
                    </div>
                    <div *ngIf="col.type=='select'&&!element['disable'+col.key]&&!col.disabled">
                        <mat-form-field appearance="outline" class=" input-form-field">
                            <mat-icon matPrefix *ngIf="col.icon">{{col.icon}}</mat-icon>
                            <mat-select  (selectionChange)="selectionChangeEvent($event,col.key+'_'+element.tblId,i)" [multiple]='col.isMulti' formControlName="{{col.key+'_'+element.tblId}}"
                                [required]="col.required">
                                <mat-option *ngFor="let option of col.options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="col.type=='auto-complete'&&!element['disable'+col.key]&&!col.disabled">
                        <app-auto-complete #autoComplete (selectionChange)="selectionChangeEvent($event,col.key+'_'+element.tblId,i)" [input]="col" [data]="element"></app-auto-complete>
                    </div>
                    <div *ngIf="col.type=='icon'&&!element['disable'+col.key]&&!col.disabled">
                        <mat-icon class="delete-icon"
                            (click)="onIconClick(element.tblId,col.key)">{{element[col.key]}}</mat-icon>
                    </div>
                    <div *ngIf="col.type=='delete'&&!element['disable'+col.key]&&!col.disabled">
                        <mat-icon class="delete-icon" (click)="deleteRow(element.tblId)">clear</mat-icon>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: tableColumns;let i=index" class="element-row" matRipple
                cdkDrag [cdkDragDisabled]="!enableDrag||!canEdit" cdkDragLockAxis="y">
            </tr>
        </table>
        <div *ngIf="footNotes" class="caption gray">{{footNotes}}</div>
        <div class="save-button-con" *ngIf="saveButtonText&&canEdit">
            <button mat-stroked-button
                *ngIf="addButtonText&&addButtonPosition=='bottom'&&(maxRows<1||maxRows>dataSource.length)"
                class="secondary-button" type="button" (click)="addRow()">{{addButtonText}}</button>
            <button mat-stroked-button class="primary-button">{{saveButtonText}}</button>
        </div>
    </form>

</div>

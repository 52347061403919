<div class="input-con-half" (click)="picker.open()">
    <mat-form-field appearance="fill" [ngClass]="isDateSelected || picker.opened? 'selected': ''">
        <label *ngIf="config?.title" class="d-inline-block w-fit-content mr-1 label">{{config?.title}}:</label>
        <mat-icon *ngIf="config?.showCalendarIcon" class="calendar-icon" (click)="picker.open()">calendar_month</mat-icon>
        <mat-date-range-input [rangePicker]="picker" class="d-inline-block w-fit-content label">
          <input matStartDate placeholder="Start date" (dateChange)="onDateRangeSelection('startDate', $event)" [(ngModel)]="startDate">
          <input matEndDate placeholder="End date" (dateChange)="onDateRangeSelection('endDate', $event)" [(ngModel)]="endDate">
        </mat-date-range-input>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel (click)="clearSelection()">Clear</button>
            <button mat-raised-button matDateRangePickerApply color="primary" (click)="emitSelectedDate()">Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
    </mat-form-field>
</div>
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SharedDialogComponent } from './shared-dialog/shared-dialog.component';
import { SearchComponent } from './search/search.component';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { NoDataBoxComponent } from './no-data-box/no-data-box.component';
import { SingleStarComponent } from './single-star/single-star.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { TimelineSelectorComponent } from './timeline-selector/timeline-selector.component';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { SearchDdDialogComponent } from './search-dropdown/search-dd-dialog/search-dd-dialog.component';
import { SkillRatingFilterDropdownComponent } from './skill-rating-filter-dropdown/skill-rating-filter-dropdown.component';
import { SkillRatingFilterDropdownDialogComponent } from './skill-rating-filter-dropdown/skill-rating-filter-dropdown-dialog/skill-rating-filter-dropdown-dialog.component';
import { PaginatorUiComponent } from './paginator-ui/paginator-ui.component';
import { ProfilePicComponent } from './profile-pic/profile-pic.component';
import { StarRatingsDialogComponent } from './star-ratings-dialog/star-ratings-dialog.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { AutoCompleteMultiselectComponent } from './auto-complete-multiselect/auto-complete-multiselect.component';
import { MultiSelectChipComponent } from './multi-select-chip/multi-select-chip.component';
import { BoxPlotChartComponent } from './box-plot-chart/box-plot-chart.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { ToastComponent } from './toast/toast.component';
import { ActionModalComponent } from './action-modal/action-modal.component';
import { DataOrderingControlComponent } from './data-ordering-control/data-ordering-control.component';
import { SearchableMultiselectComponent } from './searchable-multiselect/searchable-multiselect.component';
import { CsvMasterUploadsDownloadsComponent } from './csv-master-uploads-downloads/csv-master-uploads-downloads.component';
import { RateSkillsComponent } from './rate-skills/rate-skills.component';
import { BulkUploadModalComponent } from './bulk-upload-modal/bulk-upload-modal.component';
import { GlobalSearchableAddComponent } from './global-searchable-add/global-searchable-add.component';
import { ProgressCardComponent } from './progress-card/progress-card.component';
import { ProgressCardGroupComponent } from './progress-card-group/progress-card-group.component';
import { ProgressCardViewAllComponent } from './progress-card-view-all/progress-card-view-all.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SafeHtmlPipe } from './svg-icon/safe-html.pipe';
import { ThreeDotsAnimationComponent } from './three-dots-animation/three-dots-animation.component';
import { DoughnutChartComponent } from '../dashboards/doughnut-chart/doughnut-chart.component';
import { SpiderChartComponent } from './spider-chart/spider-chart.component';

@NgModule({
  declarations: [
    SharedDialogComponent,
    SearchComponent,
    EditableTableComponent,
    NoDataBoxComponent,
    SingleStarComponent,
    StarRatingComponent,
    TimelineSelectorComponent,
    SearchDropdownComponent,
    SearchDdDialogComponent,
    SkillRatingFilterDropdownComponent,
    SkillRatingFilterDropdownDialogComponent,
    PaginatorUiComponent,
    ProfilePicComponent,
    StarRatingsDialogComponent,
    AutoCompleteComponent,
    AutoCompleteMultiselectComponent,
    MultiSelectChipComponent,
    BoxPlotChartComponent,
    DateRangePickerComponent,
    ToastComponent,
    ActionModalComponent,
    DataOrderingControlComponent,
    SearchableMultiselectComponent,
    CsvMasterUploadsDownloadsComponent,
    RateSkillsComponent,
    BulkUploadModalComponent,
    GlobalSearchableAddComponent,
    ProgressCardComponent,
    ProgressCardGroupComponent,
    ProgressCardViewAllComponent,
    SvgIconComponent,
    SafeHtmlPipe,
    ThreeDotsAnimationComponent,
    DoughnutChartComponent,
    SpiderChartComponent
  ],
  imports: [SharedModule],
  exports: [
    SharedDialogComponent,
    SearchComponent,
    EditableTableComponent,
    NoDataBoxComponent,
    SingleStarComponent,
    StarRatingComponent,
    TimelineSelectorComponent,
    SearchDropdownComponent,
    SkillRatingFilterDropdownComponent,
    PaginatorUiComponent,
    ProfilePicComponent,
    StarRatingsDialogComponent,
    PaginatorUiComponent,
    AutoCompleteComponent,
    AutoCompleteMultiselectComponent,
    MultiSelectChipComponent,
    BoxPlotChartComponent,
    DateRangePickerComponent,
    ToastComponent,
    DataOrderingControlComponent,
    SearchableMultiselectComponent,
    CsvMasterUploadsDownloadsComponent,
    RateSkillsComponent,
    BulkUploadModalComponent,
    GlobalSearchableAddComponent,
    ProgressCardComponent,
    ProgressCardGroupComponent,
    ProgressCardViewAllComponent,
    SvgIconComponent,
    ThreeDotsAnimationComponent,
    DoughnutChartComponent,
    SpiderChartComponent
  ],
})
export class CommonComponentsModule {}

import { Component, Input, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { StarRatingsDialogComponent } from '../star-ratings-dialog/star-ratings-dialog.component';


export interface DialogData {
  value: number;
  key: string;
};

@Component({
  selector: 'app-single-star',
  templateUrl: './single-star.component.html',
  styleUrls: ['./single-star.component.scss']
})
export class SingleStarComponent implements OnInit {
  @Input() client: Client;
  @Input() key: any;
  @Input() colored: boolean = true;
  @Input() skillItem: any;
  @Input() editable: boolean;
  @Input() hideRatingValue = false;
  @Input() showRequiredProficiency: boolean = false;
  @Output() onSetValue = new EventEmitter();

  ratingOpts = {
    ratings: ['overallRating']
  }

  @ViewChild('starButton') starButton: ElementRef;
  dialogRef;

  maxValue = 5;
  constructor(public dialog: MatDialog) { }
  ngOnInit(): void {
  
    
    if (this.client && this.client.smsSettings.skillRating == 'Stars 1-4') this.maxValue = 4;
    if (this.showRequiredProficiency && !this.skillItem.requiredRating) this.showRequiredProficiency = false;
  }
  onStarClick(event) {
    this.skillItem[this.key] = event.data.value;
    this.onSetValue.emit(event.data);
  }
}


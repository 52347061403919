export interface IconConfig {
    name: string;
    color?: string;
    viewBox?: string;
    size?: string;
}

export const ICONS: { [name: string]: IconConfig } = {
    verified: {
        name: 'icons/verified-icon.svg',
        color: 'var(--success-color-dark)',
        viewBox: '0 0 24 24'
    },
    close: { 
        name: 'icons/close.svg', 
        color: 'var(--color-white)', 
        viewBox: '0 0 15 14' 
    },
    delegate: { 
        name: 'icons/handoff.svg', 
        color: 'var(--color-white)', 
        viewBox: '0 0 16 16' 
    },
    handover: { 
        name: 'icons/handoff.svg', 
        color: 'var(--color-white)', 
        viewBox: '0 0 16 16' 
    },
    success: { 
        name: 'icons/success.svg', 
        color: 'var(--color-teal)', 
        viewBox: '0 0 64 64' 
    },
    error: { 
        name: 'icons/error.svg', 
        color: 'var(--color-yellow-dark)', 
        viewBox: '0 0 64 64' 
    },
    warning_open: { 
        name: 'icons/warning-open.svg', 
        color: 'var(--color-yellow-dark)', 
        viewBox: '0 0 60 54' 
    },
    info: { 
        name: 'icons/info.svg', 
        size: '54px', 
        viewBox: '0 0 14 14', 
        color: 'var(--color-info-blue)' 
    },
    critical_error: { 
        name: 'icons/critical_error.svg', 
        color: 'var(--color-magenta-60)', 
        viewBox: '0 0 64 64' 
    },
    error_rounded: { 
        name: 'icons/error-rounded-red-big.svg', 
        color: 'var(--warning-color-dark)', 
        viewBox: '0 0 54 54' 
    },
    wait: { 
        name: 'icons/wait.svg', 
        viewBox: '0 0 64 64' 
    }
};

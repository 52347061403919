import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {

  constructor() { }
  @Input() config: any;
  @Output() dateSelected = new EventEmitter();
  @ViewChild('picker') picker: MatDateRangePicker<Moment>;

  startDate: string;
  endDate: string;
  selectedDate = {startDate: null, endDate: null};
  isDateSelected: boolean = false;

  onDateRangeSelection(type, event) {
    this.selectedDate[type] = event.value;
    this.isDateSelected = (this.selectedDate.endDate || this.selectedDate.startDate)? true: false;
  }

  clearSelection() {
    this.picker.select(undefined);
    this.selectedDate = {startDate: null, endDate: null};
    this.startDate = '';
    this.dateSelected.emit(this.selectedDate)
  }

  emitSelectedDate() {
    this.selectedDate.endDate = new Date(this.selectedDate.endDate?.getTime() + 86399999);  // To consider the whole end date.
                                                                                          // Known bug on the mat-date-range-picker
    this.dateSelected.emit(this.selectedDate)
  }

}

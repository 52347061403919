import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSidenavModule } from "@angular/material/sidenav";
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgChartsModule } from 'ng2-charts';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxFileDropModule } from 'ngx-file-drop';
import { SkeletonDirective } from 'src/app/directives/skeleton.directive';
import { SkeletonNewDirective } from 'src/app/directives/skeleton-new.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';

const modules = [
  CommonModule,
  MatButtonModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatInputModule,
  MatMenuModule,
  MatIconModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatListModule,
  CdkTableModule,
  MatTableModule,
  MatTabsModule,
  ReactiveFormsModule, FormsModule,
  HttpClientModule,
  DragDropModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  NgChartsModule,
  MatTooltipModule,
  MatRadioModule,
  AngularEditorModule,
  MatChipsModule,
  MatDividerModule,
  MatSortModule,
  MatSliderModule,
  NgxSliderModule,
  MatToolbarModule,
  RecaptchaModule,
  ScrollingModule,
  NgxFileDropModule,
]
@NgModule({
  declarations: [
    SkeletonDirective,
    SkeletonNewDirective
  ],
  imports: modules,
  exports: [
    ...modules,
    SkeletonDirective,
    SkeletonNewDirective
  ]
})
export class SharedModule { }

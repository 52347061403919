<div class="con" *ngIf="skillItem">
    <table>
        <tbody>
            <tr *ngIf="showRequiredProficiency&&skillItem.requiredRating">
                <td class="fw-700" style="margin-right:4px;" colspan="2">
                    <div>
                        <div style="margin-bottom: 6px;">Expected Proficiency</div>
                        <div style="display:flex;justify-content: flex-end;">
                            <app-star-rating key="requiredRating" [editable]="false" noTooltip="true"
                                [skillItem]="skillItem||{}" [client]="client" color="#00A2ED">
                            </app-star-rating>
                        </div>
                        <div class="proficiency-title-con">
                            <app-svg-icon class="prefix" *ngIf="skillItem.requiredRating>skillItem.overallRating"
                                src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
                            Proficiency
                        </div>
                    </div>
                </td>
            </tr>
            <tr *ngFor="let item of opts.ratings" class="rating-list-con">
                <td class="caption black" style="margin-right:4px;" *ngIf="item.title">
                    <div class="flex-con">
                        <mat-icon class="prefix mt-2" *ngIf="opts.prefix&&opts.prefix[item.key]">{{opts.prefix[item.key]}}
                        </mat-icon>
                        <div>{{item.title}}:</div>
                    </div>
                </td>
                <td [colSpan]="item.title?1:2">
                    <app-star-rating  [onEndorsementPage]="true" [greyedOut]="!item.editable || opts.prefix && (opts.prefix[item.key] != 'help')" [key]="item.key" [editable]="item.editable && opts.prefix && (opts.prefix[item.key] == 'help')" [title]="item.title" [skillItem]="skillItem||{}" [client]="client" (onSetValue)="onSetValue($event,item)"></app-star-rating>
                </td>
            </tr>
        </tbody>
    </table>
</div>

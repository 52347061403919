import { Component,EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-global-searchable-add',
  templateUrl: './global-searchable-add.component.html',
  styleUrls: ['./global-searchable-add.component.scss']
})
export class GlobalSearchableAddComponent implements OnInit {

  @Input() config: any = {};
  @Output() onEvent = new EventEmitter();
  searchText: string = '';
  searchResults:any = [] ;
  filters = [];
  filterOptions = [];
  selectedOption;

  constructor(private ds: DataService, private weds: WaitErrorDialogsService) { }

  ngOnInit(): void {
    this.filterOptions = this.config.filterDropdown?.items;
    [{ key: this.selectedOption }] = this.filterOptions;
    this.onSearchInput();
  }

  onSearchInput(){
    const endPoint = this.config.search?.isQueryParam ? `${this.config.search?.url}search=${this.searchText}` : this.config.search?.url;
    const filters = { filters: this.filters, search: this.searchText || '', ...this.config.search?.filter };
    if (this.config?.userId) {
      filters.userId = this.config?.userId;
    }
    const subscription = this.ds[this.config.search?.functionName](endPoint, filters)
      .pipe(debounceTime(300), distinctUntilChanged()).pipe(take(1)).subscribe((rs: any) => {
      this.searchResults = this.processData(rs?.searchData || rs?.data);
    });
  }

  processData(data: Array<any>): Array<any> {
    if (this.config.selectedPkList?.length) {
      data.forEach(item => item.isAdded = this.config.selectedPkList.includes(item.pk) ? true : false);
    }
    return data;
  }

  onAddItemClick(item){
    item.isAdded = !item.isAdded;
    this.onEvent.emit({type: item.isAdded? 'add': 'delete', item });
  }

  onFilterSelection() {
    this.filters = [];
    if (this.selectedOption) {
      this.filters.push({ key: this.config?.filterDropdown?.filterKey, value: [this.selectedOption] });
    }
    this.onSearchInput();
  }
}

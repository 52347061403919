export const API_ENDPOINT = {
    MY_SKILLS_V2: 'sms/v2/getMySkills',
    SAVE_MY_SKILLS: 'sms/saveMySkills',
    USER_INTERVIEW_FEEDBACK: 'sms/getUserInterviewFeedback',
    ENDORSEMENT_TEAM_SKILL_APPROVALS_V2: 'sms/v2/getTeamSkillApprovals',
    SAVE_TEAM_SKILL_APPROVALS: 'sms/v2/saveTeamSkillApprovals',
    USER_DETAILS: 'sms/user-details/',
    GET_AVAILABLE_USERS_FOR_DELEGATION: 'sms/getAvailableUsersForDelegation',
    MY_SPECIALIZATIONS: 'sms/getMySpecializations',
    MY_SPECIALIZATIONS_V2: 'sms/v2/getMySpecializations',
    VALIDATE_DYNAMIC_RESUME_TEMPLATE: 'admin/validateDynamicResumeBuilderTemplate',
    GET_ALL_RESUME_BUILDER_TEMPLATES: 'sms/getAllResumeBuilderTemplates',
    DOWNLOAD_DYNAMIC_RESUMES_BASED_ON_USERIDS: 'sms/downloadDynamicResumesBasedOnUserIds',
    GENERATE_TEMPLATE_FOR_LEARNING_PATH: 'admin/downloadTemplateForLearningPathCareerPrism',
    GENERATE_TEMPLATE_FOR_LEARNING_ELEMENT: 'admin/downloadTemplateForLearningElementCareerPrism',
    GET_SPECIALIZATION_CONFIG: 'sms-reports/getSpecConfig',
    GET_DASHBOARDS: 'sms-reports/getDashboards',
    GET_SMS_SETTINGS: 'admin/getSMSSettings',
    DOWNLOAD_DYNAMIC_RESUME_OF_SINGLE_USER: 'sms/downloadDynamicResumeOfSingleUser',
    GET_RESUMES_BUILT_FROM_PROFILE: 'sms/getResumesBuiltFromProfile',
    REQUEST_NEW_SKILL: 'sms/requestNewSkill',
    REQUEST_NEW_SKILLS_V2: 'sms/v2/raiseNewSkillsRequest',
    GET_ACM_DATA: 'admin/v2/getACMData',
    GET_ACM_REPORT_HISTORY: 'admin/v2/getACMReportHistory',
    GET_SAVED_ACM_FILTERS: 'admin/v2/getSavedACMFilters',
    SAVE_ACM_FILTERS: 'admin/v2/saveACMFilters',
    DELETE_ACM_FILTERS: 'admin/v2/deleteACMFilters',
    BULK_SAVE_MY_PROFILE: 'sms/bulkSaveMyProfile',
    GET_ACM_FILTERS: 'admin/v2/getACMFilters',
    ACM_USERS_DETAILS: 'sms/skillClusterMapping/userDetails',
    GENERATE_ACM_DOWNLOAD_CSV: 'admin/v2/generateACMReport',
    GET_ACM_FILTER_DATA: 'admin/v2/getClusterMappingFilterData',
    GET_HOME: 'sms/v2/getHome',
    SAVE_IRRELEVANT_SKILLS: 'sms/saveIrrelevantSkills',
    GET_USER_SKILLS_RECOMMENDATION: 'sms/v2/getUserSkillRecommendations',
    DELETE_SKILLS: (endPoint) => `sms/v2/${endPoint}`,
    ADD_SKILL_LE_PATH: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/`,
    UPDATE_LE_PATH: (clientId: string, primaryKey: string) => `admin/career-prism-api/learningPath/${clientId}/${primaryKey}/`,
    LE_PATH_CSV_DOWNLOAD: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/downloadableS3URI`,
    SEARCH_LE_PATH: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/search/`,
    CREATE_SOURCE_MASTER: (clientId: string) => `admin/career-prism-api/learningSource/${clientId}/`,
    UPDATE_SOURCE_MASTER: (clientId: string, primaryKey: string) => `admin/career-prism-api/learningSource/${clientId}/${primaryKey}/`,
    SEARCH_SOURCE_MASTER: (clientId: string) => `admin/career-prism-api/learningSource/${clientId}/search/`,
    LEARNING_ELEMENT_DOWNLOAD_CSV: (clientId) => `admin/career-prism-api/learningElement/${clientId}/downloadableS3URI`,
    CREATE_LEARNING_ELEMENT: (clientId: string, userId: string) => `admin/career-prism-api/learningElement/${clientId}/user/${userId}/`,
    UPDATE_LEARNING_ELEMENT: (clientId: string, primaryKey: string, userId: string) => `admin/career-prism-api/learningElement/${clientId}/${primaryKey}/user/${userId}/`,
    LEARNING_ELEMENT_FIELDS: (clientId: string) => `admin/career-prism-api/learningElement/${clientId}/fields/`,
    SEARCH_LE_MASTER: (clientId: string) => `admin/career-prism-api/learningElement/${clientId}/search/`,
    SEARCH_SMS_LE_MASTER: (clientId: string) => `sms/career-prism-api/learningElementSearchRouter/${clientId}/search/`,
    SEARCH_SKILL_FOR_LE_PATH: (clientId: string) => `admin/career-prism-api/skill/${clientId}/search/`,
    GET_IN_PROGRESS_ELEMENTS: (clientId: string, userId: string) => `sms/career-prism-api/userLearningElement/${clientId}/${userId}/search/`,
    GET_SPECIALIZATION_LIST: (clientId: string, userId: string) => `sms/career-prism-api/specialization/${clientId}/list/${userId}`,
    GET_SKILL_GAP_ANALYSIS: (clientId: string, userId: string, specializationId) => `sms/career-prism-api/specialization/${clientId}/analysis/${userId}/${specializationId}`,
    GET_LEARNING_PROGRESS_PATH: (clientId: string, userId: string, learningPathId: string, level: number) => `sms/career-prism-api/specialization/${clientId}/path/${userId}/${learningPathId}/${level}`,
    GET_LE_FOR_INDIVIDUAL: (clientId: string, userId: string) => `sms/career-prism-api/specialization/${clientId}/individual/${userId}`,
    SAVE_LE_INDIVIDUAL: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/individual/`,
    GET_SKILLS: 'admin/getSkills',
    GET_SKILLS_FROM_RESUME: 'sms/v2/getSkillsFromResume',
    GET_MY_SKILLS: 'sms/getMySkills',
    VALIDATE_MY_SPECIALIZATION: 'sms/validateMySpecialization',
    SAVE_MY_SPECIALIZATION: 'sms/saveMySpecializations',
    GET_ROLES: 'admin/getRoles',
    PUBLISH_DASHBOARDS: 'admin/publishDashboards',
    GET_EXTERNAL_BI_DASHBOARDS:'sms-reports/getExternalBiDashboards',
    GET_EXTERNAL_BI_EMBEDDED_SESSION_URL:'sms-reports/getExternalBiEmbeddedSessionUrl',
    GET_TAG_VALUES_FOR_TAG_ADMIN: 'admin/getTagValuesForTag',
    GET_DESIGNATION_LEVELS:'admin/getDesignationLevels',
    GET_UUID_FROM_EMPID:'internal/sms-admin/getUUIDFromEmpId',
    GET_DESIGNATIONS:'admin/getDesignations',
    GET_RESUME_DOWNLOAD_DETAILS: 'sms/getDynamicResumeDownloads',
    GET_ONBOARDING_L1_SKILLS: 'sms/v2/getRecommendedL1Skills',
    GET_ONBOARDING_L2_SKILLS: 'sms/v2/getRecommendedL2Skills',
    UPDATE_SKILLS_RECO_FEEDBACK: 'sms/v2/updateSkillsRecommendationFeedback',
    GET_SKILL_OVERLAY_DETAILS: 'sms/v2/getSkillOverlayDetails',
    SAVE_INTEGRATIONS: 'admin/saveIntegrations',
    INIT_SKILLS_RECOMMENDATION: 'sms/v2/initSkillsRecommendation',
    EMIT_FEEDBACK_EVENT: 'sms/v2/updateSkillsRecommendationFeedback',
    GET_DATA_FOR_CONFIRMATION_BUILDER: 'sms/getDataForConfirmationBuilder',
    GET_DYNAMIC_RESUME_DOWNLOAD: 'sms/downloadDynamicResumeBasedOnConfirmationUiData',
    DELETE_RESUME_FROM_USER_PROFILE: 'sms/deleteResumeFromUserProfile',
    GET_IJP_FILTERS: 'internal-job-posting/filters',
}

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss']
})
export class EditableTableComponent implements OnInit {
  private _dataSource: any[] = [];
  tableColumns: any[] = [];
  private _columns: any[] = [];
  @Input() addButtonText: string;
  @Input() addButtonPosition: string = 'bottom';
  @Input() enableDelete = false;
  @Input() title: string;
  @Input() footNotes: string;
  @Input() enableDrag = false;
  @Input() saveButtonText: string;
  @Input() canEdit = false;
  @Input() generateIdFromIndex = '';
  @Input() conditionalValidators: any = {};
  @Input() maxRows = 0;
  @Input() endorseLevelSettings = false;
  @Output() onSave = new EventEmitter();
  @Output() onAddRow = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onDrop = new EventEmitter();
  @Output() iconClicked = new EventEmitter();
  @ViewChildren('autoComplete') autoCompletes: QueryList<any>;
  editForm: FormGroup;
  num_controls = 0;
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.editForm) this.editForm = this.fb.group({});
  }
  @Input()
  set columns(cols: any[]) {
    this._columns = [...cols];
    if (this.enableDelete && this.canEdit) this._columns.push({ key: 'delete', type: 'delete' });
    this.tableColumns = this._columns.map((col) => { return col.key });
  }
  get columns() {
    return this._columns;
  }
  @Input()
  set dataSource(data: any[]) {
    this._dataSource = data || [];

    this.createControls();
  }
  get dataSource() {
    return this._dataSource;
  }
  createControls() {
    this.editForm = this.fb.group({});
    for (let i = 0; i < this._dataSource.length; i++) {
      if (!this._dataSource[i].tblId) this._dataSource[i].tblId = Math.round(Math.random() * 100000000);
      this._columns.map((col) => {
        if (!this.editForm.get(col.key + '_' + this._dataSource[i].tblId) && col.key != 'delete') this.editForm.addControl(col.key + '_' + this._dataSource[i].tblId, new FormControl({ value: this._dataSource[i][col.key], disabled: col.disabled || !this.canEdit || this.dataSource[i]['disable' + col.key] }, col.validators));
      })
      if(this.endorseLevelSettings){
        if(this.dataSource[i].approvalManager == 'Custom Approvers' && this.editForm.controls['customApprover_' + this._dataSource[i].tblId]){
          this.editForm.controls['customApprover_' + this._dataSource[i].tblId].setValidators([Validators.required]);
          this.editForm.controls['customApprover_' + this._dataSource[i].tblId].enable();
        }else{
          this.editForm.controls['customApprover_' + this._dataSource[i].tblId].setValue('');
          this.editForm.controls['customApprover_' + this._dataSource[i].tblId].setValidators([]);
          this.editForm.controls['customApprover_' + this._dataSource[i].tblId].disable();
        }
      }
    }
  }
  getDataFromForm(): any[] {
    let d = this.editForm.value;
    let data = [];
    for (let i = 0; i < this._dataSource.length; i++) {
      let o: any = {};
      let colHash = {};
      this._columns.map((col) => {
        if (col.type == 'auto-complete') {
          let arr = this.autoCompletes.toArray();
          for (let j = 0; j < arr.length; j++) {
            if (arr[j].input.key != col.key || arr[j].data.tblId != this._dataSource[i].tblId) continue;
            let v = arr[j].getValue();

            if (!v && col.validators && col.validators.length) break;
            o[col.key] = v;
          }
        } else if (col.key != 'delete') {
          o[col.key] = this.editForm.controls[col.key + '_' + this._dataSource[i].tblId].value;
        }
        colHash[col.key] = 1;
      });
      for (let field in this._dataSource[i]) if (['delete', 'tblId'].indexOf(field) < 0 && !colHash[field]) o[field] = this._dataSource[i][field];
      if (this._dataSource[i].dataKey) o.dataKey = this._dataSource[i].dataKey;
      data.push(o);
    }
    return data;
  }
  save() {
    if (this.editForm.invalid) return;
    let data = this.getDataFromForm();
    this.onSave.emit(data);
  }
  addRow() {
    if (this.maxRows && this.maxRows <= this.dataSource.length) return;
    this._dataSource = this.getDataFromForm();
    let o = {};
    if (this.generateIdFromIndex)
      o[this.generateIdFromIndex] = this._dataSource.length + 1;
    this._dataSource.push(o);
    this.createControls();
    this._dataSource = [...this._dataSource];
    this.onAddRow.emit(this._dataSource);
  }
  deleteRow(tblId) {
    let deleted: any = {}, deletedIndex: any;
    for (let i = 0; i < this._dataSource.length; i++) {
      if (this._dataSource[i].tblId == tblId) { deleted = this._dataSource[i]; deletedIndex = i, this._dataSource.splice(i--, 1); break; }
    }
    this._columns.map((col) => {
      if (col.key != 'delete') this.editForm.removeControl(col.key + '_' + deleted.tblId);
    })
    this._dataSource = [...this._dataSource]
    this.onDelete.emit({ data: this._dataSource, deleted: deleted, deletedIndex });
  }
  drop(event) {
    moveItemInArray(this._dataSource, event.previousIndex, event.currentIndex);
    this._dataSource = [...this._dataSource];
    this.onDrop.emit(this._dataSource);
  }

  selectionChanged(key, i) {
    const tableId = key.split('_')[1];
    const index = this._dataSource.findIndex(data => data.tblId === Number(tableId))
    if (index >= 0) {
      this._dataSource[index].modifiedByClient = true
    }
  }

  selectionChangeEvent(event,key,i){
    if(this.endorseLevelSettings && i == 0){
      const tableId = key.split('_')[1];
      if(event.value == 'Custom Approvers' && this.editForm.controls['customApprover_' + tableId]){
        this.editForm.controls['customApprover_' + tableId].setValidators([Validators.required]);
        this.editForm.controls['customApprover_' + tableId].enable();
      }else{
        this.editForm.controls['customApprover_' + tableId].setValue('');
        this.editForm.controls['customApprover_' + tableId].setValidators([]);
        this.editForm.controls['customApprover_' + tableId].disable();
      }
    } else {
      this.editForm.patchValue({
        [key]: event.selectedOption || event.value,
      })
    }
  }

  onIconClick(tblId, key) {
    this.iconClicked.emit({ data: this.dataSource.filter((item) => item.tblId == tblId)[0], key });
  }
}

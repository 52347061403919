import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { WaitErrorDialogsService } from './wait-error-dialogs.service';
import { UtilitiesService } from './utilities.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { DIALOG_TYPES, RESPONSE_STATUS } from '../constants';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';

@Injectable({
  providedIn: 'root',
})
export class CsvMasterUploadsDownloadsService {
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private utils: UtilitiesService) {}

  public downloadCSVMasterFile(href: string, name: string = '') {
    this.utils.downloadFile(href, name);
  }
  public getDownloadTemplateString(headerFields: any[]) {
    let csvHeaderRow = '';
    let csvRequiredRow = '';
    headerFields.forEach((header, index) => {
      csvHeaderRow = csvHeaderRow.concat((index ? ',' : '').concat(header.key));
      csvRequiredRow = csvRequiredRow.concat(
        (index ? ',' : '').concat(
          header.secondHeaderRowText
            ? header.secondHeaderRowText
            : header.required
            ? 'Mandatory'
            : 'Optional'
        )
      );
    });
    return csvHeaderRow.concat('\r\n', csvRequiredRow);
  }
  public getTemplateHeaderKeysMapper(entityName: string, alias: string) {
    return {
      service: [
        { key: `${alias} SkillPrism ID`, required: false },
        { key: `${alias} Name`, required: true },
        { key: `${alias} ID`, required: true },
        { key: `Owner ${alias} Line`, required: false },
        { key: 'Version', required: false },
        { key: 'Description', required: true },
        { key: 'Remarks', required: false },
        {
          key: `Delete ${alias}`,
          secondHeaderRowText: 'Optional: Yes',
        },
      ],
    }[entityName];
  }
  public async downloadCSVMaster(fileName: string, endpoint: string, payLoad: object) {
    this.weds.showDialog({ type: 'wait', code: -5 });
    const downloadCSVMaster = await this.ds
      .searchData(endpoint, payLoad)
      .toPromise();
    this.weds.closeDialog();
    const downloadCSVMasterStatus = downloadCSVMaster['status'];
    if (downloadCSVMasterStatus == 'Success') {
      const filePath = downloadCSVMaster['path'];
      this.downloadCSVMasterFile(filePath, fileName);
    } else {
      const error = downloadCSVMaster['error'];
      this.weds.showDialog({ type: 'generic', code: error ? error.code : 99 });
    }
  }
  public async getUploadConfig(clientId: string, integrationType: string) {
    return await this.ds.saveData("admin/getUploadConfig", {clientId, integrationType}).toPromise();
  }
  public uploadFileToS3PreSignedURL(s3PreSignedURL, file) {
    return this.ds.putFileToS3PreSignedUrl(s3PreSignedURL, file);
  }
  public uploadFileToS3ForIntegrationsV2(requestData: any, s3PreSignedURL: string, file: File, fileName: string) {
    requestData.type = 'Upload File Reactively';
    return this.uploadFileToS3PreSignedURL(s3PreSignedURL, file).pipe(
      switchMap(
        (res) => {
          this.weds.closeDialog();
          return this.saveIntegration(requestData, s3PreSignedURL, fileName);
        },
      ), catchError((err) => {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, title: 'Error', message: 'File not uploaded', btnTitle: 'OK' });
          return of(EMPTY);
      })
    );
  }

  saveIntegration(requestData, s3PreSignedURL, fileName) {
    return this.ds
      .saveData(API_ENDPOINT.SAVE_INTEGRATIONS, {
        ...requestData,
        s3PreSignedURL,
        file: { ...requestData.file, name: fileName },
      })
      .pipe(tap((res: any) => {
        this.weds.closeDialog();
        if (res.status === RESPONSE_STATUS.SUCCESS) {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: -1 });
        } else {
          this.weds.showDialog({
            type: DIALOG_TYPES.GENERIC,
            code: res.error ? res.error.code : 99,
          });
        }
      }));
  }
}

<div class="toast-container" *ngIf="toasts?.length">
    <div *ngFor="let toast of toasts" class="toast p-12">
        <app-svg-icon class="mr-2 mt-1px" src="icons/{{toast.icon?.src}}" 
            fill={{toast.icon?.fill}} viewBox={{toast.icon?.viewBox}}></app-svg-icon>
        <span class="msg-con mr-3">
            <div class="body white">{{toast.msg}}</div>
            <div *ngIf="toast.desc" class="label white mt-1 fw-400 desc">{{toast.desc}}</div>
            <div *ngIf="toast.actionBtnConfig" class="body white text-underline cursor-pointer mt-2" 
                (click)="toast.actionBtnConfig?.callBackFn()">{{toast.actionBtnConfig?.label}}</div>
        </span>
        <mat-icon class="white h4 cursor-pointer" (click)="toastService.hideToast(toast.id)">close</mat-icon>
    </div>
</div>

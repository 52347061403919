import { Client } from "./client.model";
import { Role } from "./role.model";

export class User {
    userId: string;
    name: string;
    initials: string;
    firstName: string;
    lastName: string;
    roleId: string;
    clientId: string;
    role: Role;
    empId: string;
    email: string;
    mobile: string;
    profileImg: string;
    profileProgress;
    onboardingComplete: boolean = false;
    reportingTo: string;
    practiceIds: string;
    designation: string;
    designationId: string;
    designationObject: any;
    hasReportee: boolean = false;
    canGetEndorsementRequest: boolean = false;
    canSeeReviewResumes: boolean = false;
    skillClusters: [{
        skillClusterId: string;
        actualProficiencyLevelOfSpecialization: string;
    }];

    deserialize(input: any) {
        if (!input) return null;
        Object.assign(this, input);
        this.role = new Role().deserialize(input.role);
        this.initials = (this.name.substr(0, 1) + (this.lastName ? this.lastName.substr(0, 1) : '')).toUpperCase();
        return this;
    }
}
